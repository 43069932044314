import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AddNewButtonComponent } from '../../buttons/add-new-button/add-new-button.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-no-data-page',
  standalone: true,
  imports: [TranslateModule, AddNewButtonComponent, NgClass],
  templateUrl: './no-data-page.component.html',
  styleUrl: './no-data-page.component.scss',
})
export class NoDataPageComponent implements OnInit {
  @Input() title: string = '';
  @Input() subTitle: string;
  @Input() type: string = '';
  @Input() description: string = '';
  @Input() isAddButton: boolean = true;
  @Input() addButtonLabel: string = '';
  @Input() isWidgetBlock: boolean = true;
  @Output() addRequest = new EventEmitter<void>();

  imageURL: string = 'assets/images/empty-root-cause-img.svg';

  requestAdd(): void {
    this.addRequest.emit();
  }

  ngOnInit(): void {
    switch (this.type) {
      case 'communication-log':
        this.imageURL = 'assets/images/empty/empty-root-cause-img.svg';
        break;
      case 'rca':
        this.imageURL = 'assets/images/empty/empty-root-cause-img.svg';
        break;
      default:
        this.imageURL = 'assets/images/empty/empty-root-cause-img.svg';
    }
  }
}
